import { setupNavigationOptions } from './options'
import { eventHandler } from './events'
import { setupPagination } from './pagination'

const compose = (...fns) => () => fns.reduceRight((v, f) => f(v))

export function CardCarousel (component = document, options = {}) {
  const carousel = component.querySelector("[data-target='carousel']")

  // carousel controls
  let currentCard, offset, cardLocations, leftButton, rightButton

  // pagination controls
  const { updatePagination, paginateNavigation } = setupPagination(component)

  // options controls
  let { setOptions, isActiveOption, getOptions } = setupNavigationOptions(options)

  // event controls
  const { getSelectedEvents, getRegisteredEvents, handleEvent } = eventHandler()

  const init = () => {
    updateCurrentCard(0)
    offset = 0
    cardLocations = determineCardLocations()

    leftButton = component.querySelector("[data-action='slideLeft']")
    rightButton = component.querySelector("[data-action='slideRight']")

    arrowNavigation()
    setupResize()
    carousel.addEventListener('transitionend', e => {
      if (e.propertyName === 'transform' && e.target === carousel) {
        focusInput()
      }
    })
  }

  const currentCardIndex = () => {
    return currentCard
  }

  const findActiveCard = () => {
    return carousel.querySelector(".swiper-slide.active[data-target='card']")
  }

  const cardStyle = () => {
    const card = carousel.querySelector("[data-target='card']")
    return card.currentStyle || window.getComputedStyle(card)
  }

  const carouselWidth = () => {
    return carousel.offsetWidth
  }

  const cardMarginRight = () => {
    let marginRight = cardStyle().marginRight.match(/\d+/g)
    if (marginRight) {
      marginRight = marginRight[0]
    }
    return Number(marginRight)
  }

  const cardCount = () => {
    return carousel.querySelectorAll("[data-target='card']").length
  }

  // controls the limit of the carousel slider
  const maxX = () => {
    const count = cardCount()
    const width = carouselWidth()
    const marginRight = cardMarginRight()
    return -(count * width +
    (marginRight * count) -
    width - marginRight)
  }

  const moveCarousel = () => {
    carousel.style.transform = `translateX(${offset}px)`
  }

  const slideTo = (cardIndex = 0) => {
    offset = cardLocations[cardIndex]
    updateCurrentCard(Number(cardIndex))
    moveCarousel()
    if (isActiveOption('paginate')) {
      updatePagination(currentCard)
    }
  }

  const setupResize = () => {
    window.addEventListener('resize', () => {
      offset = carouselWidth() + cardMarginRight()
      cardLocations = determineCardLocations()
      slideTo(currentCardIndex())
    })
  }

  const navigationOptions = () => {
    setOptions('loop', loopCards)
    setOptions('paginate', () => updatePagination(currentCard), () => paginateNavigation(slideTo))
    setOptions('defaultNav', null, defaultArrowNavigation)
    setOptions('tabbing', null)
  }

  const defaultArrowNavigation = () => {
    leftButton.addEventListener('click', () => {
      rightButton.classList.remove('swiper-button-disabled')
      const cardIndex = currentCardIndex() - 1
      if (cardIndex === 0) {
        leftButton.classList.add('swiper-button-disabled')
      }
    })

    rightButton.addEventListener('click', () => {
      leftButton.classList.remove('swiper-button-disabled')
      const cardIndex = currentCardIndex() + 1
      if (cardIndex === carousel.childElementCount - 1) {
        rightButton.classList.add('swiper-button-disabled')
      }
    })
  }

  const determineCardLocations = () => {
    if (carousel.querySelector("[data-target='card']")) {
      let numberOfCards = cardCount() - 1
      const variance = maxX() / numberOfCards
      const cards = [0]
      let increment = 0
      while (numberOfCards > 0) {
        increment = increment + variance
        cards.push(increment)
        numberOfCards--
      }
      return cards
    }
  }

  const updateCurrentCard = (cardNumber = 0) => {
    const desiredCard = carousel.querySelectorAll("[data-target='card']")[cardNumber]
    if (desiredCard) {
      const activeCard = findActiveCard()
      if (activeCard) {
        activeCard.classList.remove('active')
      }
      desiredCard.classList.add('active')
      currentCard = cardNumber
    }
  }

  const focusInput = () => {
    const input = findActiveCard().querySelector('input')
    if (input) {
      input.focus()
    }
  }

  const arrowNavigation = () => {
    navigationOptions()
    const leftArrowFunctionality = attachEventsToArrow(leftArrowEvent)
    const rightArrowFunctionality = attachEventsToArrow(rightArrowEvent)

    leftButton.addEventListener('click', leftArrowFunctionality)
    rightButton.addEventListener('click', rightArrowFunctionality)
  }

  const leftArrowEvent = (callback = () => {}) => {
    handleEvent('slideNextTransitionStart')
    let startOfCards = offset !== 0
    if (startOfCards) {
      updateCurrentCard(currentCard - 1)
      offset = offset += carouselWidth() + cardMarginRight()
    }
    startOfCards = !startOfCards
    callback(startOfCards, 'left')
    moveCarousel()
    handleEvent('transitionEnd')
  }

  const rightArrowEvent = (callback = () => {}) => {
    handleEvent('slideNextTransitionStart')
    let endOfCards = offset !== maxX()
    if (endOfCards) {
      updateCurrentCard(currentCard + 1)
      offset = offset -= carouselWidth() + cardMarginRight()
    }
    endOfCards = !endOfCards
    callback(endOfCards, 'right')
    moveCarousel()
    handleEvent('transitionEnd')
  }

  const attachEventsToArrow = (arrowEvent) => {
    const functionsToCall = [arrowEvent]
    getOptions().forEach((value, _) => {
      if (value.active && value.fn) {
        functionsToCall.push(value.fn)
      }
    })
    if (functionsToCall.length === 1) {
      functionsToCall.push(() => {})
    }
    return compose(...functionsToCall)
  }

  const loopCards = (callback = () => {}) => {
    return (call, direction) => {
      if (call) {
        if (direction === 'left') {
          updateCurrentCard(cardCount() - 1)
          offset = maxX()
        } else {
          updateCurrentCard(0)
          offset = 0
        }
      }
      callback()
    }
  }

  // allows the user to add events to the navigation buttons
  const on = (eventName = '', fn = () => {}) => {
    const registeredEvents = getRegisteredEvents()
    const selectedEvents = getSelectedEvents()

    if (registeredEvents.includes(eventName)) {
      selectedEvents.set(eventName, new Event(eventName))
      leftButton.addEventListener(eventName, fn)
      rightButton.addEventListener(eventName, fn)
      if (isActiveOption('paginate')) {
        component.querySelectorAll('span.swiper-pagination-bullet').forEach(pagination => {
          pagination.addEventListener(eventName, fn)
        })
      }
    }
  }

  return { init: init, slideTo: slideTo, on: on, currentCardIndex: currentCardIndex, focusInput: focusInput, leftArrowEvent: leftArrowEvent, rightArrowEvent: rightArrowEvent }
}