import { CardCarousel } from '../CardCarousel/index'

document.querySelectorAll('section.careers-carousel-component').forEach((carousel) => {
  const cardCarousel = new CardCarousel(carousel, { loop: true, paginate: true, tabbing: true })
  cardCarousel.init()

  document.querySelector('.swiper-button-prev').addEventListener('click', () => {
    const activeSlide = document.querySelector('.swiper-slide.active')
    const prevSlide = activeSlide.nextElementSibling

    activeSlide.setAttribute('aria-hidden', 'false')
    if (prevSlide) {
      prevSlide.setAttribute('aria-hidden', 'true')
    }
  })

  document.querySelector('.swiper-button-next').addEventListener('click', () => {
    const activeSlide = document.querySelector('.swiper-slide.active')
    const prevSlide = activeSlide.previousElementSibling

    activeSlide.setAttribute('aria-hidden', 'false')
    if (prevSlide) {
      prevSlide.setAttribute('aria-hidden', 'true')
    }
  })

  const swiperSlides = document.querySelectorAll('.swiper-slide');
  
  swiperSlides.forEach(function(swiperSlide) {
    swiperSlide.addEventListener('blur', (event) => {
      const activeCarousel = event.target.closest('.careers-carousel-component')
      const activeSlide = activeCarousel.querySelector('.swiper-slide.active')
      const nextSlide = activeSlide.nextElementSibling
      const prevSlide = activeSlide.previousElementSibling

      // forward tab
      if (nextSlide && nextSlide === event.relatedTarget) {
        
        // first tab is treated differently as it has no preceding tab
        if (prevSlide === null) {
          activeSlide.focus() // stall change in focus until the animatin begins
          activeCarousel.querySelector('.swiper-button-next').click()
          setTimeout(() => {
            nextSlide.focus() // animation must be allowed to start before setting focus on the second slide, otherwise the second slide will be skipped
          }, 100);
        } else {
          nextSlide.setAttribute('aria-hidden', 'false')
          activeCarousel.querySelector('.swiper-button-next').click()
        }

      // backward tab
      } else if (prevSlide && prevSlide === event.relatedTarget) {
        prevSlide.setAttribute('aria-hidden', 'false')
        activeCarousel.querySelector('.swiper-button-prev').click()
      }
    })
  })
})
