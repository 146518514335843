import { eventHandler } from './events'

const setupPagination = (component = document) => {
  const { handleEvent } = eventHandler()
  let activePagination = component.querySelector('span.swiper-pagination-bullet-active')

  const updatePagination = (currentCardIndex = 0) => {
    activePagination.classList.remove('swiper-pagination-bullet-active')
    const pagination = component.querySelectorAll(`span.swiper-pagination-bullet`)[currentCardIndex]
    pagination.classList.add('swiper-pagination-bullet-active')
    activePagination = pagination
  }

  const paginateNavigation = (slideTo = () => {}, tabbing = () => {}) => {
    const paginationContainer = component.querySelector('div.swiper-pagination')
    paginationContainer.addEventListener('click', e => {
      if (e.target !== e.currentTarget) {
        const pagination = e.target
        handleEvent('slideNextTransitionStart', pagination)
        activePagination.classList.remove('swiper-pagination-bullet-active')
        pagination.classList.add('swiper-pagination-bullet-active')
        activePagination = pagination
        const paginationIndex = Array.prototype.indexOf.call(pagination.parentNode.children, pagination)
        slideTo(paginationIndex)
        tabbing()
        handleEvent('transitionEnd', pagination)
      }
      e.stopPropagation()
    })
  }

  return {
    updatePagination: updatePagination,
    paginateNavigation: paginateNavigation
  }
}

export { setupPagination }
