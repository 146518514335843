
export const eventHandler = () => {
  // registered is what the lib offers and available hold what the user has selected
  const registeredEvents = ['slideNextTransitionStart', 'transitionEnd']
  let selectedEvents = new Map()

  const getRegisteredEvents = () => {
    return registeredEvents
  }

  const getSelectedEvents = () => {
    return selectedEvents
  }

  const handleEvent = (eventName, target = event.target) => {
    if (selectedEvents.has(eventName)) {
      target.dispatchEvent(selectedEvents.get(eventName))
    }
  }
  return {
    getRegisteredEvents: getRegisteredEvents,
    getSelectedEvents: getSelectedEvents,
    handleEvent: handleEvent
  }
}
